import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AuthService from './components/AuthService'
import vuetify from './plugins/vuetify'
import VueAWN from 'vue-awesome-notifications'
import 'vue-awesome-notifications/dist/styles/style.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLayers from 'vuelayers'
import mitt from 'mitt'
import VueKeepScrollPosition from 'vue-keep-scroll-position'

Vue.use(VueAWN, {
  durations: {
    global: 10000,
    success: 2000
  },
  icons: {
    prefix: '<i style="font-size:32px" class="v-icon notranslate mdi mdi-',
    suffix: '"></i>',
    success: 'check-circle',
    warning: 'alert-circle',
    alert: 'alert',
    tip: 'help-circle',
    info: 'information'
  },
  labels: {
    success: '',
    warning: '',
    alert: 'Error',
    tip: '',
    info: ''
  }
})
Vue.use(VueLayers)
Vue.use(VueKeepScrollPosition)

declare global {
  interface Window {
    // eslint-disable-next-line
    ModelViewerElement: any;
  }
}
self.ModelViewerElement = self.ModelViewerElement || {}
self.ModelViewerElement.dracoDecoderLocation = 'https://ingest.nfdi4ing.de/data/fid/js/'

const authService = new AuthService()
authService.init().then(() => {
  // waiting for init() to finish is needed because loading the token from LocalStorage is done asynchronously and
  // we need the token immediatley for the router to deny/allow protected routes.
  Vue.config.productionTip = false
  Vue.use(VueAxios, axios)
  Vue.prototype.$authService = authService
  Vue.prototype.$bus = mitt()

  axios.defaults.baseURL = process.env.VUE_APP_API_URL
  // monkey patch "fetch" for Authorization header. this is needed to load gltf models from three js.
  const { fetch: originalFetch } = window
  window.fetch = async (...args) => {
    let [resource, config] = args
    // add authorization header only to API requests and not external requests
    if (resource.toString().startsWith(process.env.VUE_APP_API_URL!)) {
      if (authService.user?.access_token) {
        if (!config) {
          config = {}
        }
        if (!config.headers) {
          config.headers = {}
        }
        // eslint-disable-next-line
        // @ts-ignore
        if (!config.headers.Authorization) {
          // eslint-disable-next-line
          // @ts-ignore
          config.headers.Authorization = 'Bearer ' + authService.user?.access_token
        }
      }
    }
    return originalFetch(resource, config)
  }

  // monkey patch XMLHttpRequest for Authorization header (instead of adding an interceptor to axios). this is needed to load gltf models.
  const o = XMLHttpRequest.prototype.open
  XMLHttpRequest.prototype.open = function () {
    // eslint-disable-next-line
    // @ts-ignore
    o.apply(this, arguments)
    if (authService.user?.access_token) {
      this.setRequestHeader('Authorization', 'Bearer ' + authService.user?.access_token)
    }
  }

  new Vue({
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})
