import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HelpPage from './views/Help.vue'
import SigninPage from './views/Signin.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "search" */ './views/Home.vue'),
    meta: { hideNavMenu: true, hideFooter: false }
    // meta: { navMenuIcon: 'mdi-view-dashboard-outline' }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ './views/Search.vue'),
    meta: { navMenuIcon: 'mdi-magnify' }
  },
  {
    path: '/plans',
    name: 'Plans',
    component: () => import(/* webpackChunkName: "plans" */ './views/Plans.vue'),
    meta: { navMenuIcon: 'mdi-floor-plan' }
  },
  {
    path: '/dataset/:id',
    name: 'Dataset',
    component: () => import(/* webpackChunkName: "dataset" */ './views/Dataset.vue'),
    meta: { hideNavMenu: true }
  },
  {
    path: '/upload',
    name: 'Upload',
    component: () => import(/* webpackChunkName: "upload" */ './views/Upload.vue'),
    meta: { protected: true, navMenuIcon: 'mdi-cloud-upload-outline' }
  },
  {
    path: '/help',
    name: 'Help',
    component: HelpPage,
    meta: { navMenuIcon: 'mdi-help-circle-outline' }
  },
  {
    path: '/signin',
    name: 'Signin',
    component: SigninPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_PATH,
  routes
})

router.beforeEach(async (to, _, next) => {
  if (to.path === Vue.prototype.$authService.signinRedirectPath()) {
    Vue.prototype.$authService.signinCallback().finally(() => {
      const path = localStorage.preSigninPath || '/'
      delete localStorage.preSigninPath
      if (path === '/upload') {
        Vue.prototype.$awn.warning('Uploading data is in <strong>beta stage</strong> and should be used for testing only.', {
          durations: { warning: 4000 },
          icons: {
            warning: 'close-circle'
          }
        })
      }
      return next({ path })
    })
  } else if (to.meta?.protected && !Vue.prototype.$authService.user) {
    // redirect to signin page
    localStorage.preSigninPath = to.path
    return next({ name: 'Signin' })
  }
  return next()
})

export default router
