<template>
  <div class="signin d-flex flex-column align-center justify-center flex-grow-1">
    <v-btn @click="$authService.login()" x-large color="primary"><v-icon dark class="mr-2">mdi-login</v-icon>Sign in</v-btn>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'SigninPage'
})
</script>
<style scoped>
.signin { height: 100%; }
</style>
