<template>
  <v-app>
    <v-app-bar app dense clipped-left color="grey darken-4" dark>
      <v-app-bar-nav-icon v-if="!$router.currentRoute?.meta?.hideNavMenu" class="hidden-md-and-up" @click.stop="drawerOpen = true"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="$router.currentRoute.name === 'Dataset'" class="pl-0"><v-btn @click="back()"><v-icon left>mdi-arrow-left</v-icon>Back</v-btn></v-toolbar-title>
      <v-toolbar-title v-else class="pl-0"><v-btn to="/" class="no-active pl-0" :ripple="false" x-large text><img alt="logo" class="mr-3 logo hidden-sm-and-down" :src="appLogo" /> {{appName}}</v-btn></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu left bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon v-if="$authService.user">mdi-account-circle</v-icon>
            <v-icon v-else>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list nav>
          <v-list-item link>
            <v-switch v-model="darkTheme" label="Dark theme" dense class="theme-switcher"></v-switch>
          </v-list-item>
          <v-list-item v-if="$authService.user" link two-line @click.stop="$authService.logout()">
            <v-list-item-icon>
              <v-icon variant="outlined">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sign out</v-list-item-title>
              <v-list-item-subtitle>{{$authService.user.profile.name}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else link @click.stop="$authService.login()">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sign in</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-if="!$router.currentRoute?.meta?.hideNavMenu" app clipped :permanent="$vuetify.breakpoint.mdAndUp" v-model="drawerOpen" width="200">
      <v-list nav>
        <v-list-item v-for="item in navMenu" :key="item.title" link :to="item.to" :class="item.clazz">
          <v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>{{ item.title }}</v-list-item-title></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="d-flex flex-column main-container">
        <keep-alive>
          <router-view v-keep-scroll-position></router-view>
        </keep-alive>
      </v-container>
    </v-main>

    <v-footer app class="d-flex justify-space-between gap-1" v-if="!$router.currentRoute?.meta?.hideFooter">
      <v-btn plain x-small :href="appFooterLink" class="text-none"><span class="app-sep"></span>{{ appFooterText }}</v-btn>
      <v-btn plain x-small :href="appFooterImprintLink" class="text-none">Imprint</v-btn>
      <v-btn plain x-small :href="appFooterPrivacyPolicyLink" class="text-none">Privacy policy</v-btn>
      <span class="flex-grow-1"></span>
      <span class="text--disabled caption">V{{ version }}<span class="hidden-sm-and-down app-sep">{{ buildDate }}</span></span>
    </v-footer>
    <div id="awn-toast-container" class="awn-bottom-right"></div>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import packageInfo from '../package.json'
import buildDate from './build-date'

type NavItem = { title: string, icon: string, to: string, clazz?: string }

export default Vue.extend({
  name: 'App',
  data: () => ({
    drawerOpen: false,
    darkTheme: localStorage.getItem('darkTheme') === 'true',
    version: packageInfo.version,
    buildDate,
    appName: process.env.VUE_APP_NAME,
    appLogo: process.env.VUE_APP_LOGO,
    appFooterText: process.env.VUE_APP_FOOTER_TEXT,
    appFooterLink: process.env.VUE_APP_FOOTER_LINK,
    appFooterImprintLink: process.env.VUE_APP_FOOTER_IMPRINT_LINK,
    appFooterPrivacyPolicyLink: process.env.VUE_APP_FOOTER_PRIVACY_POLICY_LINK
  }),
  watch: {
    darkTheme: {
      handler: function (value) {
        localStorage.setItem('darkTheme', value)
        this.$vuetify.theme.dark = value
      },
      immediate: true
    }
  },
  methods: {
    back () {
      // window.history.state is null when this was the first app page load (possibly as external link to a dataset).
      // In this case route to '/search', otherwise just go back.
      if (window.history.state === null) {
        this.$router.replace('/search')
      } else {
        this.$router.back()
      }
    }
  },
  computed: {
    navMenu (): NavItem[] {
      const navItems: NavItem[] = []
      for (const route of this.$router.getRoutes()) {
        if (route.meta.navMenuIcon) {
          const navItem: NavItem = { title: route.name || '', icon: route.meta.navMenuIcon as string, to: route.path || '/' }
          if (route.meta.protected && !this.$authService.user) {
            navItem.clazz = 'text--disabled'
          }
          navItems.push(navItem)
        }
      }
      return navItems
    }
  }
})
</script>

<style>
.logo { height: 30px; }
.container.main-container { height: 100%; padding: 0; }
.monospace { font-family: monospace; }
.text-x-small { font-size:0.7em !important; }
.v-btn.no-active::before { opacity: 0 !important; }
.theme-switcher::v-deep .v-input--selection-controls__input { width:48px; }
.theme--dark.theme-switcher::v-deep label { color:white; }
.theme--light.theme-switcher::v-deep label { color:rgba(0, 0, 0, 0.87); }
.app-sep::before { content:'//'; color: var(--v-warning-base); }
#awn-toast-container.awn-bottom-right { bottom:68px; }
.awn-toast { max-width: calc(100vw - 48px);}
.theme--dark input, .theme--dark select, .theme--dark textarea { color: #FFFFFF; }
.gap-1 { gap: 10px; }
</style>
