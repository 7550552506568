import { User, UserManager } from 'oidc-client-ts'
import Vue from 'vue'

const signinRedirectPath = '/oidc-callback'

const settings = {
  authority: `${process.env.VUE_APP_IDM_URL}/realms/${process.env.VUE_APP_IDM_REALM}`,
  client_id: `${process.env.VUE_APP_IDM_CLIENT_ID}`,
  redirect_uri: `${window.location.origin}${process.env.BASE_URL?.replace(/\/$/, '')}${signinRedirectPath}`,
  post_logout_redirect_uri: window.location.origin,
  response_type: 'code',
  scope: 'openid profile email'
}

export default Vue.extend({
  created () {
    this.userManager.events.addUserLoaded(this._setUser)
    this.userManager.events.addAccessTokenExpired(() => {
      this._setUser(null)
    })
  },
  data (): {
    userManager: UserManager;
    user: User | null
    } {
    return {
      userManager: new UserManager(settings),
      user: null
    }
  },
  methods: {
    async init () {
      await this.userManager.getUser().then(this._setUser).catch(e => {
        console.log(e)
      })
    },
    signinRedirectPath (): string {
      return signinRedirectPath
    },
    login (): Promise<void> {
      return this.userManager.signinRedirect()
    },
    logout (): Promise<void> {
      return this.userManager.signoutRedirect()
    },
    signinCallback (): Promise<void | User> {
      return this.userManager.signinCallback()
    },
    _setUser (user: User | null) {
      if (!user) {
        this.user = null
      } else if (user.expired) {
        this.userManager.signinSilent().catch((e) => {
          // NOP
          console.log(e)
        })
      } else {
        this.user = user
      }
    }
  }
})
